import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Footer = () => (


  <footer class="w-full py-8">
    <div class="container mx-auto px-4">
      
      <div class="sm:flex flex-wrap justify-between tems-center text-center ">
        <div class="sm:w-1/3 text-center sm:text-left text-gray-500 dark:text-gray-200 font-light">
          © {new Date().getFullYear()} MinimumSites
        </div>
        <div class="flex max-w-xs mx-auto text-center items-center justify-center">
          <a href="https://instagram.com/ulises.roofing" class="text-gray-400 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <rect x="4" y="4" width="16" height="16" rx="4"></rect>
              <circle cx="12" cy="12" r="3"></circle>
              <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
            </svg>
          </a>
        </div>
        <div class="text-center sm:text-right sm:w-1/3 text-gray-500 dark:text-gray-200 font-light sm:text-right">
          Ulises Roofing, serving the GTA since 2008
        </div>
      </div>
    </div>
  </footer>


)

export default Footer
