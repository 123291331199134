import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import logo from "../images/logo.svg"

const Header = ({ siteTitle }) => (

  <nav class="flex bg-white flex-wrap items-center p-4 text-center justify-center content-center shadow">
    <div>
      <a class="text-2xl text-gray-800 font-semibold font-heading" href="#">
        <div>
          <img src={logo} alt="logo for ulises roofing toronto"/>
        </div>
        <div className="text-sm text-gray-500 font-medium">Quality roofing services in the GTA</div>
      </a>
    </div>
  </nav>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
